<template>
    <div class="login">
        <div class="form" v-if="!service.isAuthorized()" @keydown.enter="auth">
            <div class="title">Вход в систему</div>
            <div class="field" v-show="message" v-html="message"></div>
            <div class="field">
                <input-box v-model="email" placeholder="E-mail" :error="error.email"></input-box>
            </div>
            <div class="field">
                <input-box v-model="password" placeholder="Пароль" :error="error.password"></input-box>
            </div>
            <div class="field">
                <div class="button-box" @click="auth">Войти</div>
            </div>
        </div>
        <div class="form" v-if="service.isAuthorized()">
            <div class="title">Вход в систему</div>
        </div>
    </div>
    <div class="login-description"></div>
</template>

<script>
import InputBox from "../components/fields/InputBox";
import { AuthService } from "../services/auth.service";

export default {
    name: "Login",
    components: {InputBox},
    methods: {
        auth() {
            this.error.email = !this.email;
            this.error.password = !this.password;
            if (this.email && this.password) {
                this.message = '';
                this.service.login(this.email, this.password)
                    .then(response => {
                        if (response) {
                            this.$router.push('/');
                        } else {
                            this.message = 'Something went wrong.'
                        }
                    })
                    .catch(error => {
                        this.message = 'Something went wrong.<br>' + error
                    })

            }
        }
    },
    data() {
        return {
            service: AuthService,
            message: '',
            email: null,
            password: null,
            error: {
                email: false,
                password: false
            }
        }
    }
}
</script>

<style scoped>

</style>